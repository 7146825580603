<div class="input-group">
  <button
    class="btn btn-outline-secondary calendar-button"
    (click)="hijri.toggle()"
    type="button"
  >
    <img src="../../../../assets/images/calendar.svg" alt="" />
  </button>

  <input
    onkeydown="return false"
    class="form-control"
    placeholder="DD/MM/YYYY"
    name="dp"
    [minDate]="minDate"
    [maxDate]="maxDate"
    [(ngModel)]="hijriDate"
    ngbDatepicker
    #hijri="ngbDatepicker"
    (ngModelChange)="onHijriInputChange($event)"
    (click)="hijri.toggle()"
  />
</div>
