import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/app/environment/environment';

@Injectable({ providedIn: 'root' })
export class HttpHelperService {
  constructor(private http: HttpClient) {}

  public SendPostRequest<R>(
    url: string,
    request?: any,
    customHeaders?: Headers,
    isStopSendToken?: boolean
  ) {
    return this.http.post<R>(environment.BackEndURL + '/' + url, request);
  }

  public SendPostRequestSync<R>(
    url: string,
    request?: any,
    customHeaders?: { [key: string]: string },
    isStopSendToken?: boolean
  ): R | undefined {
    const fullUrl = environment.BackEndURL + '/' + url;

    const xhr = new XMLHttpRequest();
    xhr.open('POST', fullUrl, false); // false makes the request synchronous

    if (customHeaders) {
      for (const key in customHeaders) {
        if (customHeaders.hasOwnProperty(key)) {
          xhr.setRequestHeader(key, customHeaders[key]);
        }
      }
    }

    xhr.setRequestHeader('Accept', 'application/json');
    xhr.setRequestHeader('Content-type', 'application/json');
    xhr.setRequestHeader('apikey', environment.apikey);
    xhr.setRequestHeader('appcode', environment.appcode);
    xhr.setRequestHeader('withCredentials', 'true');
    xhr.setRequestHeader('X-Frame-Options', 'DENY');
    xhr.setRequestHeader(
      'Authorization',
      btoa(environment.username + ':' + environment.password)
    );

    const storedToken = localStorage.getItem('token');

    if (
      storedToken != null &&
      storedToken != '' &&
      storedToken != undefined &&
      storedToken != 'undefined'
    ) {
      xhr.setRequestHeader('token', storedToken);
    }

    xhr.send(JSON.stringify(request));
    if (xhr.status === 200) {
      return JSON.parse(xhr.responseText) as R;
    } else {
      throw new Error(`Request failed with status ${xhr.status}`);
    }
  }

  public SendPostRequestAndReturnView(
    url: string,
    request?: any,
    customHeaders?: Headers,
    isStopSendToken?: boolean
  ) {
    return this.http.post(environment.BackEndURL + '/' + url, request, {
      responseType: 'text',
    });
  }

  public SendGetRequest<R>(
    url: string,
    customHeaders?: Headers,
    isStopSendToken?: boolean
  ) {
    return this.http.get<R>(environment.BackEndURL + '/' + url);
  }
}
