import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ApplicationLanguages } from 'src/app/enums/lang';
import { SignUpService } from 'src/app/pages/sign-up/services/sign-up.service';
import { ChangeLanguageService } from 'src/app/services/helper/change-language.service';

@Component({
  selector: 'app-sign-up-nafath-verification',
  standalone: false,
  templateUrl: './nafath-verification.component.html',
  styleUrl: './nafath-verification.component.scss',
})
export class NafathVerificationComponent {
  userData$ = this.signUpService.userData$;
  applicationLanguages = ApplicationLanguages;

  constructor(
    private readonly signUpService: SignUpService,
    private readonly toaster: ToastrService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly translate: TranslateService,
    protected readonly changeLanguageService: ChangeLanguageService
  ) {}

  verifyNafath() {
    this.signUpService.NafathCheck().subscribe({
      next: (res) => {
        if (res.succeeded) {
          this.toaster.success(
            '',
            this.translate.instant('account-has-been-created')
          );

          this.openPaymentOptionsPage();
        } else {
          this.toaster.error('', res.message ?? '');
        }
      },
      error: (error) => {
        this.toaster.error('', error.error?.detail);
      },
    });
  }

  openPaymentOptionsPage() {
    this.router.navigate(['/payment-options'], {
      queryParams: this.route.snapshot.queryParams,
    });
  }
}
