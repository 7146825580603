import { Component, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { first, Subject } from 'rxjs';
import { OnboardingStep } from 'src/app/models/signUp';
import { NationalIdConfirmationService } from 'src/app/pages/sign-up/services/national-id-confirmation.service';
import { PhoneVerificationFailureService } from 'src/app/pages/sign-up/services/phone-verification-failure.service';
import { SignUpService } from 'src/app/pages/sign-up/services/sign-up.service';

@Component({
  selector: 'app-sign-up-national-id-confirmation',
  standalone: false,
  templateUrl: './national-id-confirmation.component.html',
  styleUrl: './national-id-confirmation.component.scss',
})
export class NationalIdConfirmationComponent {
  isRequestPending: boolean = false;
  isModalOpened$ = this.nationalIdConfirmationService.isModalOpened$;
  nationalId$ = this.nationalIdConfirmationService.nationalId$;

  @Output() openNextStep = new Subject<keyof typeof OnboardingStep>();

  constructor(
    private readonly signUpService: SignUpService,
    private readonly toaster: ToastrService,
    private readonly nationalIdConfirmationService: NationalIdConfirmationService,
    private readonly phoneVerificationFailureService: PhoneVerificationFailureService
  ) {}

  handleCloseModal() {
    this.nationalIdConfirmationService.closeModal();
  }

  handleOpenNextStep() {
    this.openNextStep.next('addressverified');
  }

  verifyId() {
    this.isRequestPending = true;

    this.signUpService.userData.pipe(first()).subscribe((userData) => {
      if (userData.isVerificationPendingAfterOTP) {
        this.isRequestPending = false;
        this.handleCloseModal();
        this.phoneVerificationFailureService.openFailureModal();
        return;
      }

      this.nationalId$.pipe(first()).subscribe((nationalId) => {
        this.signUpService.CreateNafathRequest(nationalId).subscribe({
          next: (res) => {
            this.isRequestPending = false;
            this.handleCloseModal();

            if (res.succeeded) {
              this.signUpService.SetTransIdAndRandomAndNationalId(
                res.data.transId,
                res.data.random,
                nationalId
              );
              this.handleOpenNextStep();
            } else if (res.code === 1002) {
              this.phoneVerificationFailureService.openFailureModal();
            }
          },
          error: (error) => {
            this.isRequestPending = false;
            if (error.error.code === 1002) {
              this.handleCloseModal();
              this.phoneVerificationFailureService.openFailureModal();
            } else {
              this.toaster.error('', error.error?.detail);
            }
          },
        });
      });
    });
  }
}
