import { Component, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject, take } from 'rxjs';
import { OnboardingStep } from 'src/app/models/signUp';
import { SignUpService } from 'src/app/pages/sign-up/services/sign-up.service';
import { TermsConfirmationService } from 'src/app/pages/sign-up/services/terms-confirmation.service';

@Component({
  selector: 'app-sign-up-terms-confirmation',
  standalone: false,
  templateUrl: './terms-confirmation.component.html',
  styleUrl: './terms-confirmation.component.scss',
})
export class TermsConfirmationComponent {
  @Output() openNextStep = new Subject<keyof typeof OnboardingStep>();

  isTermsAccepted = false;
  isRequestPending = false;
  isModalOpened$ = this.termsConfirmationService.isFailureModalOpened$;
  userInformation$ = this.termsConfirmationService.userInformation$;

  constructor(
    protected readonly termsConfirmationService: TermsConfirmationService,
    private readonly signUpService: SignUpService,
    private readonly toaster: ToastrService
  ) {}

  handleSaveUserPersonalData() {
    this.userInformation$.pipe(take(1)).subscribe((userInformation) => {
      this.isRequestPending = true;
      this.signUpService
        .SaveUserPersonalData({
          ...userInformation,
          referralCode: userInformation.isReferralCodeCorrect
            ? userInformation.referralCode
            : '',
        })
        .subscribe({
          next: (res) => {
            this.isRequestPending = false;

            this.signUpService.setOnboardingStep(
              OnboardingStep[res.data.onboardingStep]
            );

            this.toaster.success('', res.data.successMessage);

            this.termsConfirmationService.closeTermsConfirmationModal();
            this.handleOpenNextStep(res.data.onboardingStep);
          },
          error: (error) => {
            this.isRequestPending = false;

            this.toaster.error('', error.error?.detail);
          },
        });
    });
  }

  handleOpenNextStep(nextStep: keyof typeof OnboardingStep) {
    this.openNextStep.next(nextStep);
  }

  closeModal() {
    this.termsConfirmationService.closeTermsConfirmationModal();
  }
}
