<div class="pages-layout">
  <div class="container d-flex flex-column align-items-center">
    <div class="content">
      <div class="logo-container">
        <app-cancel-order-modal
          *ngIf="!isOrderSuccessPage"
          [orderDetailsRes]="orderDetailsRes"
        />

        <img
          [src]="
            changeLanguageService.getLanguage() === applicationLanguages.Ar
              ? '../../../assets/images/shared/madfu-pages-logo-ar.svg'
              : '../../../assets/images/shared/madfu-pages-logo.svg'
          "
          class="img-fluid madfu-logo"
          alt="Madfu"
        />

        <img
          *ngIf="isPaymentMethodsPage"
          src="../../../assets/images/back-btn.svg"
          class="img-fluid back-button"
          alt="back"
          (click)="goBack()"
          (keydown.enter)="goBack()"
        />
      </div>

      <img
        *ngIf="isPaymentOptionsPage"
        src="../../../assets/images/shared/progress-bar-1.svg"
        class="img-fluid progress-bar"
        alt="Madfu"
      />

      <img
        *ngIf="isPaymentMethodsPage"
        src="../../../assets/images/shared/progress-bar-2.svg"
        class="img-fluid progress-bar"
        alt="Madfu"
      />

      <img
        *ngIf="
          !isOrderSuccessPage && !isPaymentOptionsPage && !isPaymentMethodsPage
        "
        src="../../../assets/images/shared/progress-bar-3.svg"
        class="img-fluid progress-bar"
        alt="Madfu"
      />

      <router-outlet> </router-outlet>

      <button
        *ngIf="isPaymentPages"
        type="button"
        class="btn btn-text col-12 change-language-btn"
        (click)="changeLanguage()"
      >
        {{ "English" | translate }}
      </button>
    </div>
  </div>
</div>
