<form [formGroup]="userDataForm" (ngSubmit)="openTermsConfirmationModal()">
  <div class="row">
    <div class="col-12 col-lg-6 pe-lg-1 pb-2">
      <label class="input-label" for="firstName">
        {{ "first-name" | translate }}
      </label>
      <div class="input-group phone-input-group">
        <div class="input-group-prepend">
          <img
            src="/assets/images/sign-up/user.svg"
            class="img-fluid"
            alt="user"
          />
        </div>

        <input
          id="firstName"
          [placeholder]="'first-name' | translate"
          formControlName="firstName"
          [ngClass]="{
            'red-border':
              controls['firstName'].errors && controls['firstName'].touched
          }"
        />

        <div
          *ngIf="controls['firstName'].errors && controls['firstName'].touched"
          class="col-12 mt-1 error-message"
        >
          @if(controls['firstName'].errors['required']) {
          {{ "required" | translate }}
          } @else if (controls['firstName'].errors['pattern']) {
          {{ "field-should-contain-only-letters" | translate }}
          }
        </div>
      </div>
    </div>

    <div class="col-12 col-lg-6 ps-lg-1 pb-2">
      <label class="input-label" for="lastName">
        {{ "last-name" | translate }}
      </label>
      <div class="input-group phone-input-group">
        <div class="input-group-prepend">
          <img
            src="/assets/images/sign-up/user.svg"
            class="img-fluid"
            alt="user"
          />
        </div>

        <input
          id="lastName"
          [placeholder]="'last-name' | translate"
          formControlName="lastName"
          [ngClass]="{
            'red-border':
              controls['lastName'].errors && controls['lastName'].touched
          }"
        />

        <div
          *ngIf="controls['lastName'].errors && controls['lastName'].touched"
          class="col-12 mt-1 error-message"
        >
          @if(controls['lastName'].errors['required']) { } @else if
          (controls['lastName'].errors['pattern']) {
          {{ "field-should-contain-only-letters" | translate }}
          }
        </div>
      </div>
    </div>

    <div class="col-12 pb-2">
      <label class="input-label" for="email">
        {{ "email" | translate }}
      </label>
      <div class="input-group phone-input-group">
        <div class="input-group-prepend">
          <img
            src="/assets/images/sign-up/email.svg"
            class="img-fluid"
            alt="user"
          />
        </div>

        <input
          id="email"
          [placeholder]="'email' | translate"
          formControlName="email"
          [ngClass]="{
            'red-border': controls['email'].errors && controls['email'].touched
          }"
        />

        <div
          *ngIf="controls['email'].errors && controls['email'].touched"
          class="col-12 mt-1 error-message"
        >
          @if(controls['email'].errors['required']) {
          {{ "required" | translate }}
          } @else if(controls['email'].errors['pattern']) {
          {{ "invalid-email" | translate }}
          }
        </div>
      </div>
    </div>
  </div>

  <!--Hide Gender for now-->
  <!--
  <div class="row">
    <div class="col-12 py-0">
      <label class="input-label" for="gender">
        {{ "gender" | translate }}
      </label>
    </div>

    <div class="col-6 pe-1 pb-2">
      <div class="gender-option">
        <div class="check-box-container">
          <input
            id="male-gender"
            type="checkbox"
            [checked]="controls['gender'].value === 1"
            [value]="1"
            (change)="selectGender(1)"
          />
          <span
            class="checkmark"
            (click)="selectGender(1)"
            (keydown.enter)="selectGender(1)"
          ></span>
          <label for="male-gender">
            {{ "male" | translate }}
          </label>
        </div>
      </div>
    </div>

    <div class="col-6 ps-1 pb-2">
      <div class="gender-option">
        <div class="check-box-container">
          <input
            id="female-gender"
            type="checkbox"
            [checked]="controls['gender'].value === 2"
            [value]="2"
            (change)="selectGender(2)"
          />
          <span
            class="checkmark"
            (click)="selectGender(2)"
            (keydown.enter)="selectGender(2)"
          ></span>
          <label for="female-gender">
            {{ "female" | translate }}
          </label>
        </div>
      </div>
    </div>
  </div>
  -->

  <div class="row">
    <div class="col-12 col-lg-6 pe-lg-1 pb-2">
      <label class="input-label" for="password">
        {{ "password" | translate }}
      </label>
      <div class="input-group phone-input-group">
        <div class="input-group-prepend">
          <img
            src="/assets/images/sign-up/lock.svg"
            class="img-fluid"
            alt="lock"
          />
        </div>

        <input
          id="password"
          class="with-append-icon"
          [type]="isPasswordVisible ? 'text' : 'password'"
          [placeholder]="'password' | translate"
          formControlName="password"
          [ngClass]="{
            'red-border':
              controls['password'].errors && controls['password'].touched
          }"
          autocomplete="new-password"
        />

        <div class="input-group-append curser-pointer">
          <img
            [src]="
              isPasswordVisible
                ? '/assets/images/sign-up/eye.svg'
                : '/assets/images/sign-up/eye-slash.svg'
            "
            class="img-fluid"
            alt="eye"
            (click)="togglePasswordVisibility()"
            (keydown.enter)="togglePasswordVisibility()"
          />
        </div>

        <div
          *ngIf="controls['password'].errors && controls['password'].touched"
          class="col-12 mt-1 error-message"
        >
          {{ "password-error-message" | translate }}
        </div>
      </div>
    </div>

    <div class="col-12 col-lg-6 ps-lg-1 pb-2">
      <label class="input-label" for="confirmPassword">
        {{ "confirm-password" | translate }}
      </label>
      <div class="input-group phone-input-group">
        <div class="input-group-prepend">
          <img
            src="/assets/images/sign-up/lock.svg"
            class="img-fluid"
            alt="lock"
          />
        </div>

        <input
          id="confirmPassword"
          class="with-append-icon"
          [type]="isConfirmPasswordVisible ? 'text' : 'password'"
          [placeholder]="'confirm-password' | translate"
          formControlName="confirmPassword"
          [ngClass]="{
            'red-border':
              (controls['confirmPassword'].errors &&
                controls['confirmPassword'].touched) ||
              userDataForm.hasError('passwordMismatch')
          }"
        />

        <div class="input-group-append curser-pointer">
          <img
            [src]="
              isConfirmPasswordVisible
                ? '/assets/images/sign-up/eye.svg'
                : '/assets/images/sign-up/eye-slash.svg'
            "
            class="img-fluid"
            alt="eye"
            (click)="toggleConfirmPasswordVisibility()"
            (keydown.enter)="toggleConfirmPasswordVisibility()"
          />
        </div>

        @if((controls['confirmPassword'].errors &&
        controls['confirmPassword'].touched) ||
        userDataForm.hasError('passwordMismatch')) {
        <div class="col-12 mt-1 error-message">
          @if((controls['confirmPassword'].errors &&
          controls['confirmPassword'].touched)) {

          {{ "required" | translate }}
          } @else if (userDataForm.hasError('passwordMismatch')) {
          {{ "password-mismatch" | translate }}
          }
        </div>
        }
      </div>
    </div>

    <div class="col-12 pb-2">
      <label class="input-label" for="referralCode">
        {{ "referral-code" | translate }}
      </label>
      <div class="input-group phone-input-group">
        <div class="input-group-prepend">
          <img
            src="/assets/images/sign-up/referral.svg"
            class="img-fluid"
            alt="user"
          />
        </div>

        <input
          id="referralCode"
          [placeholder]="
            ('referral-code' | translate) +
            ' (' +
            ('optional' | translate) +
            ')'
          "
          formControlName="referralCode"
          [ngClass]="{
            'red-border':
              controls['referralCode'].errors &&
              controls['referralCode'].touched,
            'green-border': isReferralCodeCorrect
          }"
          (change)="onChangeReferralCode()"
          minlength="6"
          maxlength="6"
        />

        @if(controls['referralCode'].errors && controls['referralCode'].touched)
        {
        <div class="col-12 mt-1 error-message">
          @if(controls['referralCode'].hasError('minlength') ||
          controls['referralCode'].hasError('maxlength')) {
          {{ "code-should-be-6-chars" | translate }}
          } @else if (controls['referralCode'].hasError('invalid')) {
          {{ "referral-code-incorrect" | translate }}
          }
        </div>
        } @else if(isReferralCodeCorrect) {
        <div class="col-12 mt-1 success-message">
          {{ "referral-code-applied" | translate }}
        </div>
        }
      </div>
    </div>
  </div>

  <div class="row privacy-policy">
    <div class="col-12">
      <div class="check-box-container">
        <label for="acceptPrivacyPolicy">
          <input
            id="acceptPrivacyPolicy"
            type="checkbox"
            formControlName="acceptPrivacyPolicy"
          />
          <span class="checkmark"></span>

          <p>
            {{ "privacy-policy-agreement" | translate }}
            <a class="text" href="https://madfu.com.sa/privacy" target="_blank">
              {{ "privacy-policy" | translate }}
            </a>
          </p>
        </label>
      </div>
    </div>
  </div>

  <button
    type="submit"
    [disabled]="isRequestPending || userDataForm.invalid"
    class="btn btn-primary continue-button"
  >
    {{ "continue" | translate }}

    <img
      src="/assets/images/login/side-arrow.svg"
      class="img-fluid ms-2"
      alt="arrow"
    />
  </button>
</form>
