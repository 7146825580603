import { Component } from '@angular/core';
import { PhoneVerificationFailureService } from 'src/app/pages/sign-up/services/phone-verification-failure.service';

@Component({
  selector: 'app-sign-up-phone-verification-failed',
  standalone: false,
  templateUrl: './phone-verification-failed.component.html',
  styleUrl: './phone-verification-failed.component.scss',
})
export class PhoneVerificationFailedComponent {
  isModalOpened$ = this.phoneVerificationFailureService.isFailureModalOpened$;

  constructor(
    private readonly phoneVerificationFailureService: PhoneVerificationFailureService
  ) {}

  openChangePhoneModal() {
    this.phoneVerificationFailureService.closeFailureModal();
    this.phoneVerificationFailureService.openChangeMobileModal();
    this.phoneVerificationFailureService.startChangeMobileNumberMode();
  }
}
