import { Component } from '@angular/core';
import { PhoneVerificationFailureService } from 'src/app/pages/sign-up/services/phone-verification-failure.service';

@Component({
  selector: 'app-sign-up-phone-verification-limit-reached',
  standalone: false,
  templateUrl: './phone-verification-limit-reached.component.html',
  styleUrl: './phone-verification-limit-reached.component.scss',
})
export class PhoneVerificationLimitReachedComponent {
  isModalOpened$ =
    this.phoneVerificationFailureService.isReachedTriesLimitsModalOpened$;

  constructor(
    private readonly phoneVerificationFailureService: PhoneVerificationFailureService
  ) {}

  contactCallCenter() {
    window.open('https://madfu.com.sa/complaints', '_blank');
  }
}
