import { Injectable } from '@angular/core';
import { Analytics, logEvent } from '@angular/fire/analytics';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor(private readonly analytics: Analytics) {}

  orderId: number = -1;

  sendEvent(eventName: string, event?: { [key: string]: any }) {
    console.log('sendEvent', eventName);
    //
    // const timestamp = new Date().toISOString();
    //
    // logEvent(this.analytics, eventName, {
    //   orderID: this.orderId,
    //   timestamp,
    //   ...event,
    // });
  }
}
