import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TermsConfirmationService {
  private readonly isTermsConfirmationModalOpened: Subject<boolean> =
    new Subject<boolean>();
  isFailureModalOpened$: Observable<boolean> =
    this.isTermsConfirmationModalOpened.asObservable();

  private readonly userInformation: BehaviorSubject<UserData> =
    new BehaviorSubject<UserData>({
      firstName: '',
      lastName: '',
      email: '',
      gender: 1,
      password: '',
      confirmPassword: '',
      referralCode: '',
      isReferralCodeCorrect: false,
    });
  userInformation$: Observable<UserData> = this.userInformation.asObservable();

  constructor() {}

  openTermsConfirmationModal(userInformation: UserData) {
    this.userInformation.next(userInformation);
    this.isTermsConfirmationModalOpened.next(true);
  }

  closeTermsConfirmationModal() {
    this.isTermsConfirmationModalOpened.next(false);
  }
}

interface UserData {
  firstName: string;
  lastName: string;
  email: string;
  gender: number;
  password: string;
  confirmPassword: string;
  referralCode: string;
  isReferralCodeCorrect: boolean;
}
