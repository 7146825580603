<div class="birth-date-payment-confirm">
  <div class="row">
    <div class="col-12 d-flex flex-column align-items-center">
      <img
        loading="lazy"
        src="../../../assets/images/birthdate-verification.svg"
        class="img-fluid birth-date-verification-image"
        alt="birth-date-verification"
      />

      <div class="title">
        {{ "verify-birth-date" | translate }}
      </div>

      <div class="subtitle">
        {{ "enter-your-birth-date" | translate }}
      </div>

      <div class="switcher">
        <input
          id="toggle-on"
          class="toggle toggle-left"
          name="toggle"
          value="hijri"
          type="radio"
          [(ngModel)]="dateType"
          (change)="onDateTypeChange('hijri')"
        />
        <label for="toggle-on" class="btn"> {{ "hijri" | translate }}</label>

        <input
          id="toggle-off"
          class="toggle toggle-right"
          name="toggle"
          value="gregorian"
          type="radio"
          [(ngModel)]="dateType"
          (change)="onDateTypeChange('gregorian')"
        />
        <label for="toggle-off" class="btn">
          {{ "gregorian" | translate }}</label
        >
      </div>

      <div class="date-pickers">
        <div class="input-group" *ngIf="dateType == 'gregorian'">
          <button
            class="btn btn-outline-secondary calendar-button"
            (click)="dgregorian.toggle()"
            type="button"
          >
            <img src="../../../../assets/images/calendar.svg" alt="calendar" />
          </button>

          <input
            onkeydown="return false"
            class="form-control"
            placeholder="DD/MM/YYYY"
            name="dp"
            [minDate]="minDate"
            [maxDate]="maxDate"
            [(ngModel)]="gregorianDate"
            ngbDatepicker
            #dgregorian="ngbDatepicker"
            (ngModelChange)="onInputChange($event)"
            (click)="dgregorian.toggle()"
          />
        </div>

        <app-hijri-date-picker
          *ngIf="dateType == 'hijri'"
          (selectedHijriDate)="onInputChange($event)"
        />

        <p class="error" *ngIf="showDateOfBirthError">
          {{ dateOfBirthErrorMsg }}
        </p>
      </div>

      <button
        class="btn btn-primary approve-button"
        (click)="confirm()"
        [disabled]="!selectedDate"
      >
        {{ "confirm" | translate }}
        <img
          src="../../../assets/images/login/side-arrow.svg"
          class="img-fluid ms-2"
          alt="side-arrow"
        />
      </button>
    </div>
  </div>
</div>
