import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ANALYTICS_EVENTS } from 'src/app/constants';
import { OrderDetailsRes } from 'src/app/models/getOrderDetails';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { HelperService } from 'src/app/services/Helper.service';
@Component({
  selector: 'app-cancel-order-modal',
  standalone: false,
  templateUrl: './cancel-order-modal.component.html',
  styleUrl: './cancel-order-modal.component.scss',
})
export class CancelOrderModalComponent {
  @Input() isLogin: boolean = false;
  @Input({ required: true }) orderDetailsRes?: OrderDetailsRes;

  @ViewChild('cancelOrderModal') cancelOrderModal?: ElementRef;
  isOpened = false;

  constructor(
    private helperService: HelperService,
    private readonly analyticsService: AnalyticsService
  ) {}

  openModal() {
    this.analyticsService.sendEvent(ANALYTICS_EVENTS.SHARED.CANCEL_ORDER);
  }

  cancelOrder() {
    this.analyticsService.sendEvent(
      ANALYTICS_EVENTS.SHARED.CANCEL_ORDER_CONFIRMATION
    );

    if (window.parent) {
      window.parent.postMessage(
        { callback: 'cancelCallback', data: null },
        '*'
      );

      this.helperService.HandleCancelPaymentResult(this.orderDetailsRes);
    }
  }

  declineCancelOrder() {
    this.analyticsService.sendEvent(
      ANALYTICS_EVENTS.SHARED.CANCEL_ORDER_DECLINE
    );
  }
}
