<div
  *ngIf="isModalOpened$ | async"
  class="modal fade nationalIdConfirmationModal show"
  id="nationalIdConfirmationModal"
  aria-labelledby="nationalIdConfirmationModalLabel"
  tabindex="-1"
  style="display: block"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <div class="title-container">
          <p class="title mb-1">
            {{ "terms-and-conditions-approval" | translate }}
          </p>

          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="closeModal()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="terms">
          <div class="check-box-container">
            <label for="acceptTerms">
              <input
                id="acceptTerms"
                type="checkbox"
                [(ngModel)]="isTermsAccepted"
              />
              <span class="checkmark"></span>

              <p>
                {{ "terms-and-conditions-agreement" | translate }}
                <a
                  class="text"
                  href="https://madfu.com.sa/terms-and-conditions"
                  target="_blank"
                >
                  {{ "terms-conditions" | translate }}
                </a>
              </p>
            </label>
          </div>
        </div>

        <button
          type="button"
          class="btn btn-primary confirm-btn"
          [disabled]="!isTermsAccepted || isRequestPending"
          (click)="handleSaveUserPersonalData()"
        >
          {{ "agree" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isModalOpened$ | async" class="modal-backdrop fade show"></div>
