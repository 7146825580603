<div
  *ngIf="isModalOpened$ | async"
  class="modal fade nationalIdConfirmationModal show"
  id="nationalIdConfirmationModal"
  aria-labelledby="nationalIdConfirmationModalLabel"
  tabindex="-1"
  style="display: block"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <img
          src="/assets/images/sign-up/national-id-onfirmation.png"
          class="img-fluid error-image"
          alt="Madfu"
        />

        <div>
          <p class="title mb-1">
            {{ "identity-confirmation" | translate }}
          </p>

          <p class="subtitle">
            {{ "your-national-id-is" | translate }}
            <span class="national-id">{{ nationalId$ | async }}</span>
          </p>
        </div>

        <button
          type="button"
          class="btn btn-primary confirm-btn"
          (click)="verifyId()"
          [disabled]="isRequestPending"
        >
          {{ "confirm" | translate }}
        </button>

        <button
          type="button"
          class="btn btn-primary cancel-btn"
          (click)="handleCloseModal()"
        >
          {{ "cancel" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isModalOpened$ | async" class="modal-backdrop fade show"></div>
