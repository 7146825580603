<div
  *ngIf="isModalOpened$ | async"
  class="modal fade nationalIdConfirmationModal show"
  id="nationalIdConfirmationModal"
  aria-labelledby="nationalIdConfirmationModalLabel"
  tabindex="-1"
  style="display: block"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <img
          src="/assets/images/sign-up/hone-ownership-error.png"
          class="img-fluid error-image"
          alt="Madfu"
        />

        <div>
          <p class="title mb-lg-1">
            {{ "phone-ownership-failed" | translate }}
          </p>

          <p class="subtitle">
            {{ "mobile-not-match-the-id" | translate }}
          </p>
        </div>

        <button
          type="button"
          class="btn btn-primary confirm-btn"
          (click)="openChangePhoneModal()"
        >
          {{ "try-again" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isModalOpened$ | async" class="modal-backdrop fade show"></div>
