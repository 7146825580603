import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NationalIdConfirmationService } from 'src/app/pages/sign-up/services/national-id-confirmation.service';
import { SignUpService } from 'src/app/pages/sign-up/services/sign-up.service';

@Component({
  selector: 'app-sign-up-national-id-verification',
  standalone: false,
  templateUrl: './national-id-verification.component.html',
  styleUrl: './national-id-verification.component.scss',
})
export class NationalIdVerificationComponent {
  nationalIdForm: FormGroup;

  constructor(
    readonly fb: FormBuilder,
    readonly signUpService: SignUpService,
    private nationalIdConfirmationService: NationalIdConfirmationService
  ) {
    this.nationalIdForm = this.fb.group({
      nationalId: [
        '',
        [Validators.required, Validators.pattern(/^[1-2]\d{9}$/)],
      ],
    });
  }

  get nationalIdControl() {
    return this.nationalIdForm.controls['nationalId'];
  }

  openNationalIdModal() {
    if (this.nationalIdControl.valid) {
      this.nationalIdConfirmationService.openModal(
        this.nationalIdControl.value
      );
    }
  }
}
