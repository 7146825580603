<div class="nafath-verification">
  <p class="random-number">{{ (userData$ | async)?.random }}</p>

  <div class="item-container">
    <img src="/assets/images/sign-up/nafath.png" alt="nafath" class="nafath" />

    <p class="text">
      {{ "download-nafath-app" | translate }}
      <span>{{ "on-your-phone" | translate }}</span>
    </p>
  </div>

  <div class="item-container">
    <img src="/assets/images/sign-up/choose-number.png" alt="choose number" />

    <p class="text">{{ "choose-above-number" | translate }}</p>
  </div>

  <div class="item-container">
    <img
      [src]="
        changeLanguageService.getLanguage() === applicationLanguages.Ar
          ? '/assets/images/sign-up/mini-logo-ar.png'
          : '/assets/images/sign-up/mini-logo.png'
      "
      class="img-fluid min-logo"
      alt="mini logo"
    />

    <p class="text">{{ "back-to-madfu" | translate }}</p>
  </div>

  <button
    type="button"
    class="btn btn-primary sign-up-button"
    (click)="verifyNafath()"
    [tabindex]="-1"
  >
    {{ "finish-sign-up" | translate }}
  </button>
</div>
