<div
  *ngIf="isModalOpened"
  class="modal fade error-modal show"
  id="errorModal"
  aria-labelledby="errorModalLabel"
  tabindex="-1"
  style="display: block"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <img
          src="../../../assets/images/error-modal.svg"
          class="img-fluid error-image"
          alt="Madfu"
        />

        <p class="title">
          {{ title }}
        </p>

        <p class="subtitle">
          {{ message }}
        </p>

        <button
          type="button"
          class="btn btn-primary try-again-btn"
          (click)="tryAgain()"
        >
          {{ "try-again" | translate }}

          <img
            src="../../../assets/images/login/side-arrow.svg"
            class="img-fluid ms-2"
            alt="side arrow"
          />
        </button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isModalOpened" class="modal-backdrop fade show"></div>
