<form
  [formGroup]="loginForm"
  (ngSubmit)="handleLogin()"
  (keydown.enter)="handleEnterPress()"
>
  <div class="form-row align-items-center login-form">
    <div class="col-auto mb-3">
      <div class="input-group phone-input-group">
        <div class="input-group-prepend">
          <div class="input-group-text input-logo">
            <img
              src="../../../assets/images/sa-flag.svg"
              class="img-fluid"
              alt="SA flag"
            />
          </div>
        </div>

        <input
          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
          (focusout)="checkValidation()"
          (focus)="OnFocusInsideInput()"
          minlength="9"
          maxlength="9"
          type="tel"
          class="form-control"
          appLeadingZero
          id="UserMobile"
          placeholder="5xx xxx xxx"
          formControlName="mobile"
          [ngClass]="{
            'is-invalid': loginForm.get('mobile')?.hasError('invalidLength')
          }"
        />

        <div *ngIf="invalidMobileLen" class="col-12 mt-1 is-invalid-title">
          {{ "Mobile-number-must-consist-of-nine-digits" | translate }}
        </div>

        <div
          *ngIf="loginForm.get('mobile')?.hasError('incorrectMobile')"
          class="col-12 mt-1 is-invalid-title"
        >
          {{ msgError }}
        </div>
      </div>
    </div>

    <div class="col-auto">
      <button
        type="submit"
        [disabled]="isRequestPending || loginForm.invalid"
        class="btn btn-primary col-12 login-btn"
      >
        {{ "sign in" | translate }}

        <img
          src="../../../assets/images/login/side-arrow.svg"
          class="img-fluid ms-2"
          alt="arrow"
        />
      </button>
    </div>

    <div class="col-auto">
      <button
        type="button"
        [disabled]="isRequestPending || loginForm.invalid"
        class="btn btn-primary col-12 login-btn sign-up-btn"
        (click)="handleSignUp()"
      >
        {{ "sign-up" | translate }}
      </button>
    </div>
  </div>
</form>
