<div class="row">
  <div class="col-12">
    <iframe
      title="visa payment"
      id="threeDsIFrame"
      [src]="sanitizedThreeDSUrl"
      name="threeDsIFrame"
      #threeDsIFrame
    ></iframe>
  </div>
</div>
