import { Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { OrderDetailsRes } from 'src/app/models/getOrderDetails';
import { CheckoutService } from 'src/app/services/checkout.service';
import { ChangeLanguageService } from 'src/app/services/helper/change-language.service';
import { ApplicationLanguages } from 'src/app/enums/lang';
import { OnboardingStep } from 'src/app/models/signUp';
import { PhoneVerificationFailureService } from './services/phone-verification-failure.service';
import { NationalIdConfirmationService } from './services/national-id-confirmation.service';
import { SignUpService } from './services/sign-up.service';

@Component({
  selector: 'app-sign-up',
  standalone: false,
  templateUrl: './sign-up.component.html',
  styleUrl: './sign-up.component.scss',
})
export class SignUpComponent {
  orderDetailsRes: OrderDetailsRes | undefined;

  destroyRef = inject(DestroyRef);

  applicationLanguages = ApplicationLanguages;

  currentStep = 0;

  constructor(
    private readonly checkoutService: CheckoutService,
    protected changeLanguageService: ChangeLanguageService,
    private readonly phoneVerificationFailureService: PhoneVerificationFailureService,
    private readonly nationalIdConfirmationService: NationalIdConfirmationService,
    private readonly signUpService: SignUpService
  ) {}

  ngOnInit(): void {
    this.subscribeOrderDetails();
  }

  ngOnDestroy(): void {
    this.phoneVerificationFailureService.resetData();
    this.nationalIdConfirmationService.resetData();
    this.signUpService.resetData();
  }

  subscribeOrderDetails() {
    this.checkoutService.orderDetails
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res: OrderDetailsRes) => {
        this.orderDetailsRes = res;
      });
  }

  changeLanguage() {
    this.changeLanguageService.changeLanguage();
  }

  openNextStepHandler(step: keyof typeof OnboardingStep) {
    const selectedStep = OnboardingStep[step];

    if (selectedStep === 4) {
      this.phoneVerificationFailureService.openFailureModal();
    } else if (selectedStep > this.currentStep) {
      for (let i = this.currentStep; i < selectedStep; i++) {
        /*
          I added setTimeout because the currentStep is not updating immediately and not updating when changing the value
        */
        setTimeout(() => {
          this.currentStep++;
        }, 0);
      }
    } else if (selectedStep < this.currentStep) {
      for (let i = this.currentStep; i > selectedStep; i--) {
        setTimeout(() => {
          this.currentStep--;
        }, 0);
      }
    }
  }
}
