import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  CreateNafathRequestRes,
  NafathCheckRes,
  OnboardingStep,
  SignUpData,
  SignUpOTPVerificationReq,
  SignUpOTPVerificationRes,
  SignUpPersonalDataReq,
  SignUpPersonalDataRes,
  SignUpSendOTPReq,
  SignUpSendOTPRes,
} from 'src/app/models/signUp';
import { HttpHelperService } from 'src/app/services/base/http-helper.services';

@Injectable({
  providedIn: 'root',
})
export class SignUpService {
  constructor(private httpHelperService: HttpHelperService) {}

  userData = new BehaviorSubject<SignUpData>({
    mobile: '',
    idGuid: '',
    onboardingStep: OnboardingStep.otp,
    nationalId: '',
    random: '',
    transId: '',
    isVerificationPendingAfterOTP: false,
  });
  userData$: Observable<SignUpData> = this.userData.asObservable();

  public setMobileAndIdGuid(mobile: string, idGuid: string) {
    this.userData.next({
      ...this.userData.value,
      mobile,
      idGuid,
    });
  }

  public setIdGuid(idGuid: string) {
    this.userData.next({
      ...this.userData.value,
      idGuid,
    });
  }

  public SetTransIdAndRandomAndNationalId(
    transId: string,
    random: string,
    nationalId: string
  ) {
    this.userData.next({
      ...this.userData.value,
      random,
      transId,
      nationalId,
    });
  }

  public resetData() {
    this.userData.next({
      mobile: '',
      idGuid: '',
      onboardingStep: OnboardingStep.otp,
      nationalId: '',
      random: '',
      transId: '',
      isVerificationPendingAfterOTP: false,
    });
  }

  public setOnboardingStep(onboardingStep: OnboardingStep) {
    this.userData.next({
      ...this.userData.value,
      onboardingStep,
    });
  }

  public setVerificationPendingAfterOTP(
    isVerificationPendingAfterOTP: boolean
  ) {
    this.userData.next({
      ...this.userData.value,
      isVerificationPendingAfterOTP,
    });
  }

  public SendOTP(req: SignUpSendOTPReq) {
    return this.httpHelperService.SendPostRequest<SignUpSendOTPRes>(
      'api/Users/SignupOTPSending',
      req
    );
  }

  public VerifyOTP = (req: SignUpOTPVerificationReq) => {
    return this.httpHelperService.SendPostRequest<SignUpOTPVerificationRes>(
      'api/Users/SignupOTPVerification',
      req
    );
  };

  public SaveUserPersonalData = (data: SignUpPersonalDataReq) => {
    return this.httpHelperService.SendPostRequest<SignUpPersonalDataRes>(
      'api/Users/SignupUserPersonalDataSave',
      data
    );
  };

  public CreateNafathRequest = (nationalId: string) => {
    return this.httpHelperService.SendPostRequest<CreateNafathRequestRes>(
      'api/Users/SignupNafathRequest',
      { nationalId: nationalId }
    );
  };

  public NafathCheck = () => {
    return this.httpHelperService.SendPostRequest<NafathCheckRes>(
      'api/Users/SignupNafathCheck',
      {
        nationalId: this.userData.value.nationalId,
        transId: this.userData.value.transId,
        random: this.userData.value.random,
      }
    );
  };

  public ChangeMobile(req: SignUpSendOTPReq) {
    return this.httpHelperService.SendPostRequest<SignUpSendOTPRes>(
      'api/Users/SignupChangeMobile',
      req
    );
  }

  public VerifyChangedMobileOTP = (req: SignUpOTPVerificationReq) => {
    return this.httpHelperService.SendPostRequest<SignUpOTPVerificationRes>(
      'api/Users/SignupChangeMobileOTPVerification',
      req
    );
  };

  public ValidateReferralCode = (referralCode: string) => {
    return this.httpHelperService.SendGetRequest<any>(
      'api/Users/ValidateReferralCode?ReferralCode=' + referralCode
    );
  };
}
