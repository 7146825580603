import { Component } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { SignUpService } from 'src/app/pages/sign-up/services/sign-up.service';
import { TermsConfirmationService } from 'src/app/pages/sign-up/services/terms-confirmation.service';

@Component({
  selector: 'app-sign-up-personal-data',
  standalone: false,
  templateUrl: './personal-data.component.html',
  styleUrl: './personal-data.component.scss',
})
export class PersonalDataComponent {
  userDataForm: FormGroup;
  isRequestPending = false;
  isPasswordVisible = false;
  isConfirmPasswordVisible = false;
  isReferralCodeCorrect = false;

  constructor(
    private readonly fb: FormBuilder,
    private readonly signUpService: SignUpService,
    private readonly termsConfirmationService: TermsConfirmationService
  ) {
    this.userDataForm = this.fb.group(
      {
        firstName: [
          '',
          [
            Validators.required,
            Validators.pattern(/^[\u0600-\u06FFa-zA-Z\s]+$/),
          ],
        ],
        lastName: [
          '',
          [
            Validators.required,
            Validators.pattern(/^[\u0600-\u06FFa-zA-Z\s]+$/),
          ],
        ],
        email: [
          '',
          [
            Validators.required,
            Validators.pattern(
              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
            ),
          ],
        ],
        gender: [1],
        password: [
          '',
          [
            Validators.required,
            Validators.pattern(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{}|;:'",.<>?/])[A-Za-z\d!@#$%^&*()_+\-=[\]{}|;:'",.<>?/]{8,}$/
            ),
          ],
        ],
        confirmPassword: ['', Validators.required],
        referralCode: ['', [Validators.minLength(6), Validators.maxLength(6)]],
        acceptPrivacyPolicy: [false, Validators.requiredTrue],
      },
      {
        validators: this.passwordMatchValidator,
      }
    );
  }

  get controls() {
    return this.userDataForm.controls;
  }

  passwordMatchValidator(control: AbstractControl) {
    return control?.get('password')?.value ===
      control?.get('confirmPassword')?.value
      ? null
      : {
          passwordMismatch: true,
          requiredValue: 'confirmPassword must match password',
        };
  }

  selectGender(gender: number) {
    this.userDataForm.patchValue({ gender });
  }

  togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  toggleConfirmPasswordVisibility() {
    this.isConfirmPasswordVisible = !this.isConfirmPasswordVisible;
  }

  onChangeReferralCode() {
    this.isReferralCodeCorrect = false;

    const referralCodeControl = this.userDataForm.controls['referralCode'];

    if (referralCodeControl?.errors) {
      const errors = { ...referralCodeControl.errors };
      if (errors['invalid']) {
        delete errors['invalid'];
        referralCodeControl.setErrors(
          Object.keys(errors).length ? errors : null
        );
      }
    } else {
      this.validateReferralCode();
    }
  }

  validateReferralCode() {
    if (
      this.userDataForm.controls['referralCode'].valid &&
      this.userDataForm.controls['referralCode'].value
    ) {
      this.signUpService
        .ValidateReferralCode(this.userDataForm.controls['referralCode'].value)
        .subscribe({
          next: (res) => {
            if (res.data) {
              this.isReferralCodeCorrect = true;
            } else {
              this.isReferralCodeCorrect = false;
              this.userDataForm.controls['referralCode'].setErrors({
                invalid: true,
              });
            }
          },
          error: (error) => {
            this.isReferralCodeCorrect = false;
            this.userDataForm.controls['referralCode'].setErrors({
              invalid: true,
            });
          },
        });
    }
  }

  openTermsConfirmationModal() {
    if (this.userDataForm.valid && !this.isRequestPending) {
      this.termsConfirmationService.openTermsConfirmationModal({
        ...this.userDataForm.value,
        isReferralCodeCorrect: this.isReferralCodeCorrect,
      });
    }
  }
}
