<form
  [formGroup]="otpForm"
  (ngSubmit)="handleCheckOTP()"
  (keydown.enter)="handleEnterPress()"
>
  <div class="form-row align-items-center">
    <div class="col-auto">
      <div class="input-group otp-group">
        <input
          type="tel"
          id="otp1"
          maxlength="1"
          placeholder=""
          formControlName="otp1"
          (keyup)="moveFocus($event, 'otp2', '', 'otp1')"
          [ngClass]="{
            'red-border': otpForm.hasError('incorrectOTP')
          }"
        />

        <input
          type="tel"
          id="otp2"
          maxlength="1"
          placeholder=""
          formControlName="otp2"
          (keyup)="moveFocus($event, 'otp3', 'otp1', 'otp2')"
          [ngClass]="{
            'red-border': otpForm.hasError('incorrectOTP')
          }"
        />

        <input
          type="tel"
          id="otp3"
          maxlength="1"
          placeholder=""
          formControlName="otp3"
          (keyup)="moveFocus($event, 'otp4', 'otp2', 'otp3')"
          [ngClass]="{
            'red-border': otpForm.hasError('incorrectOTP')
          }"
        />

        <input
          type="tel"
          id="otp4"
          maxlength="1"
          placeholder=""
          formControlName="otp4"
          (keyup)="moveFocus($event, '', 'otp3', 'otp4')"
          [ngClass]="{
            'red-border': otpForm.hasError('incorrectOTP')
          }"
        />
      </div>
    </div>

    <div class="resend-area text-center">
      <span *ngIf="showSubmitMsg" class="mt-3 expire-messgae">
        {{ "Did-not-receive-the-code" | translate }}
        <span>
          <button
            class="resend-button"
            (click)="this.resendOTP()"
            type="button"
            [disabled]="showVerificationError || countdown > 0"
          >
            {{ "Resend-the-code" | translate }}
          </button>
        </span>
        {{ "in" | translate }}
        {{ "00:" }}{{ countdown | number : "2.0" }}
        {{ "seconds" | translate }}
      </span>

      <span *ngIf="showVerificationError" class="mt-3 verification-error">
        {{
          errorMessage
            ? errorMessage
            : ("The-verification-code-is-incorrect-try-again" | translate)
        }}

        <span>
          <button
            class="resend-button"
            (click)="this.resendOTP()"
            type="button"
          >
            {{ "Resend-the-code" | translate }}
          </button>
        </span>
      </span>
    </div>

    <div class="d-flex flex-nowrap gap-3">
      <button
        type="button"
        class="btn btn-primary previous-button"
        (click)="openLoginPage()"
      >
        {{ "previous" | translate }}
      </button>

      <button
        type="submit"
        [disabled]="!enableSubmitBtn || otpForm.invalid"
        class="btn btn-primary check-otb-button"
      >
        {{ "confirm" | translate }}
      </button>
    </div>
  </div>
</form>
