<div class="payment-options">
  <div class="row">
    <div class="col-12">
      <div class="cart-amount-container mb-3">
        <div class="d-flex justify-content-between align-items-center">
          <div
            class="your-cart d-flex justify-content-between align-items-center"
          >
            <img
              src="../../../assets/images/payment-options/shopping-bag.svg"
              alt="cart"
              class="cart-icon"
            />

            <p class="ms-2">
              {{ "your-cart" | translate }}
            </p>
          </div>

          <div>
            <p class="amount">
              {{ totalAmount }}
              <span>{{ "Riyal" | translate }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-lg-6">
      <app-payment-schedule
        [paymentPlan]="selectedPaymentOption?.paymentPlan"
        [cashBackEnable]="cashBackEnable"
        [paidAmountNowAfterWallet]="
          selectedPaymentOption?.paidAmountNowAfterWallet ?? 0
        "
        [isFreeLastInstalment]="
          selectedPaymentOption?.isFreeLastInstalment ?? false
        "
        [paymentOptionType]="selectedPaymentOption?.paymentOptionType"
      ></app-payment-schedule>
    </div>

    <div *ngIf="!fullCashBack" class="payment-options-widget col-12 col-lg-6">
      <p class="section-title">{{ "payment-options" | translate }}</p>

      <div class="payment-ways">
        <div
          class="payment-way"
          [ngClass]="{ selected: selectedPaymentType === paymentType.Card }"
        >
          <label class="container-radio">
            <input
              type="radio"
              name="paymentOptionGroup"
              id="master-visa"
              (change)="onChangePaymentType(paymentType.Card)"
              [(ngModel)]="selectedPaymentType"
              [value]="paymentType.Card"
            />
            <span class="checkmark"></span>
          </label>

          <div class="payment-way-details">
            <img
              loading="lazy"
              src="../../assets/images/master-visa.svg"
              style="object-fit: cover"
              alt="master-visa"
            />
            <div class="payment-way-details-titles">
              <div class="title">
                {{ "Visa-MasterCard" | translate }}
              </div>
              <div class="subtitle">
                {{ "Pay-through-Visa" | translate }}
              </div>
            </div>
          </div>
        </div>

        <div
          class="payment-way"
          [ngClass]="{ selected: selectedPaymentType === paymentType.MADA }"
        >
          <label class="container-radio">
            <input
              type="radio"
              name="paymentOptionGroup"
              id="master-visa"
              (change)="onChangePaymentType(paymentType.MADA)"
              [(ngModel)]="selectedPaymentType"
              [value]="paymentType.MADA"
            />
            <span class="checkmark"></span>
          </label>

          <div class="payment-way-details">
            <img
              loading="lazy"
              src="../../assets/images/mada-visa.png"
              style="object-fit: contain"
              alt="mada-visa"
            />
            <div class="payment-way-details-titles">
              <div class="title">
                {{ "mada-Visa-card" | translate }}
              </div>
              <div class="subtitle">
                {{ "Pay-through-Mada" | translate }}
              </div>
            </div>
          </div>
        </div>

        <div
          *ngIf="isAllowApplePay"
          class="payment-way"
          [ngClass]="{ selected: selectedPaymentType === paymentType.ApplePay }"
        >
          <label class="container-radio">
            <input
              type="radio"
              name="paymentOptionGroup"
              id="master-visa"
              (change)="onChangePaymentType(paymentType.ApplePay)"
              [(ngModel)]="selectedPaymentType"
              [value]="paymentType.ApplePay"
            />
            <span class="checkmark"></span>
          </label>

          <div class="payment-way-details">
            <img
              loading="lazy"
              src="../../assets/images/apple-pay.svg"
              style="object-fit: cover"
              alt="apple-pay"
            />
            <div class="payment-way-details-titles">
              <div class="title">
                {{ "ApplePay" | translate }}
              </div>
              <div class="subtitle">
                {{ "Pay-through-ApplePay" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <app-promo-code [referralOffer]="referralOffer" [orderId]="orderId" />
    </div>

    <div class="terms-and-conditions col-12">
      <label class="check-box-container">
        <input
          type="checkbox"
          [(ngModel)]="termsValue"
          (change)="onTermsAndConditionsClick()"
        />
        <span class="checkmark"></span>

        {{ "confirm-read-terms-and-conditions" | translate }}
        <a
          class="terms-link"
          href="https://madfu.com.sa/terms-and-conditions"
          target="_blank"
        >
          {{ "terms-and-conditions" | translate }}
        </a>
      </label>
    </div>

    <div class="col-12 p-0">
      <button
        [disabled]="!termsValue"
        type="button"
        class="pay-btn btn btn-primary col-12"
        (click)="startPaymentOperation()"
      >
        {{ "pay" | translate }}
        {{ firstPayment }}
        <span class="rial">{{ "Riyal" | translate }}</span>
        {{ "now" | translate }}

        <img
          src="../../../assets/images/login/side-arrow.svg"
          class="img-fluid ms-2"
          alt="arrow"
        />
      </button>
    </div>
  </div>
</div>
