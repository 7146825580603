export const environment = {
  BackEndURL: 'https://api.staging.madfu.com.sa',
  registerLink: 'https://onelink.to/ekb96t',
  production: false,
  username: 'bnplrootusername',
  password: 'L8nGq7edHMJQgoZTZInesy6ZmedMtZNSHQ7c7d4qbozrr',
  apikey: 'a712d3ba-7ce8-11eb-8e6f-0',
  appcode: 'BNPL01',
  androidStoreLink:
    'https://play.google.com/store/apps/details?id=com.sa.app.madfuser',
  iOSStoreLink:
    'https://apps.apple.com/us/app/madfu-%D9%85%D8%AF%D9%81%D9%88%D8%B9/id1658723268',
  redirectPeriodInMilliSecond: 5000,
  firebaseConfig: {
    apiKey: 'AIzaSyCGEShaS6a6Y_FfufZv0fhqloO1wFwnMfQ',
    authDomain: 'checkoutpage-3301d.firebaseapp.com',
    projectId: 'checkoutpage-3301d',
    storageBucket: 'checkoutpage-3301d.appspot.com',
    messagingSenderId: '273203626783',
    appId: '1:273203626783:web:9b85f368027150dfda662a',
    measurementId: 'G-XRED4VY7K9',
  },
};
