import { Component, Input } from '@angular/core';
import { PaymentOptionType } from 'src/app/enums/PaymentType';
import { PaymentPlan } from 'src/app/models/paymentOption';

@Component({
  selector: 'app-payment-schedule',
  standalone: false,
  templateUrl: './payment-schedule.component.html',
  styleUrl: './payment-schedule.component.scss',
})
export class PaymentScheduleComponent {
  @Input({ required: true }) paymentPlan: PaymentPlan[] | undefined;
  @Input({ required: true }) cashBackEnable = false;
  @Input({ required: true }) paidAmountNowAfterWallet = 0;
  @Input({ required: true }) isFreeLastInstalment = false;
  @Input({ required: true }) paymentOptionType: number | undefined = 0;

  paymentOptionTypes = PaymentOptionType;

  getCircleProgress(i: number) {
    const totalPayments = this.paymentPlan?.length ?? 0;

    return parseInt(((i / totalPayments) * 100).toString());
  }
}
