<div class="pages-layout">
  <div class="container d-flex flex-column align-items-center">
    <div class="content">
      <app-sign-up-terms-confirmation
        (openNextStep)="openNextStepHandler($event)"
      />

      <app-sign-up-national-id-confirmation
        (openNextStep)="openNextStepHandler($event)"
      />

      <app-sign-up-phone-verification-failed />

      <app-sign-up-change-phone-number
        (openNextStep)="openNextStepHandler($event)"
      />

      <app-sign-up-phone-verification-limit-reached />

      <div class="logo-container">
        <app-cancel-order-modal [orderDetailsRes]="orderDetailsRes" />

        <img
          [src]="
            changeLanguageService.getLanguage() === applicationLanguages.Ar
              ? '../../../assets/images/shared/madfu-pages-logo-ar.svg'
              : '../../../assets/images/shared/madfu-pages-logo.svg'
          "
          class="img-fluid madfu-logo"
          alt="Madfu"
        />
      </div>

      <div class="data-container">
        <img
          src="/assets/images/otp/madfu-otp-page.svg"
          class="img-fluid heading-image"
          alt="Madfu"
        />

        <p class="get-started">{{ "get-started-with-madfu" | translate }}</p>

        <ng-stepper
          #cdkStepper
          [linear]="true"
          style="width: 100%"
          [selectedIndex]="currentStep"
        >
          <cdk-step>
            <ng-template cdkStepLabel>
              <div class="step-bullet"></div>
              <div class="step-title">{{ "otp-verification" | translate }}</div>
            </ng-template>

            <app-sign-up-otp-verification
              (openNextStep)="openNextStepHandler($event)"
            />
          </cdk-step>

          <cdk-step>
            <ng-template cdkStepLabel>
              <div class="step-bullet"></div>
              <div class="step-title">{{ "your-information" | translate }}</div>
            </ng-template>

            <app-sign-up-personal-data />
          </cdk-step>

          <cdk-step>
            <ng-template cdkStepLabel>
              <div class="step-bullet"></div>
              <div class="step-title">
                {{ "national-id-verification" | translate }}
              </div>
            </ng-template>

            <app-sign-up-national-id-verification />
          </cdk-step>

          <cdk-step>
            <ng-template cdkStepLabel>
              <div class="step-bullet"></div>
              <div class="step-title">
                {{ "nafath-verification" | translate }}
              </div>
            </ng-template>

            <app-sign-up-nafath-verification />
          </cdk-step>
        </ng-stepper>

        <button
          type="button"
          class="btn btn-text col-12 change-language-btn"
          (click)="changeLanguage()"
        >
          {{ "English" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
