import { PaymentOption } from '../models/paymentOption';

export enum PaymentType {
  Card = 1,
  ApplePay = 2,
  MADA = 3,
}

export enum statusCode {
  success = 200,
  needYakeenVerification = 433,
  CouldNotProcessOTP = 444,
  Completed = 125,
}

export enum paymentErrorText {
  invalidOTPCode = 'Invalid OTP code. Please enter the correct code or request a new one.',
}

export enum PaymentOptionType {
  PayInFull = 1,
  Installments = 2,
  PayDay = 3,
}

export interface PaymentData {
  orderId: number | undefined;
  customerName: string;
  selectedPaymentOptionId: number;
  selectedPaymentOption: PaymentOption | undefined;
  noOfInstallments: number;
  cashBackEnable: boolean;
  fullCashBack: boolean;
  referralCode: number | undefined;
  paymentOptionTitle: string;
  paidAmountNow: number;
  amountFromCashBack: number;
  isAllowApplePay: boolean;
  selectedPaymentType: PaymentType;
  // needed in OTP verification
  invalidOTPCode: string;
  // from OTP
  otp: string;
  idGuid: string;
  // needed in the three DS
  paymentPageLink: string;
  // from three DS
  cashbackFromOrder: number;
  // needed in apple pay
  merchantReference: string;
  // needed in success page
  orderReferenceNumber: string;
  offerId: string;
  referralOffer: { code: string; id: number } | undefined;
}
