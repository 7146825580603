import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NationalIdConfirmationService {
  private readonly isModalOpened: Subject<boolean> = new Subject<boolean>();
  isModalOpened$: Observable<boolean> = this.isModalOpened.asObservable();

  private readonly nationalId: BehaviorSubject<string> =
    new BehaviorSubject<string>('');
  nationalId$: Observable<string> = this.nationalId.asObservable();

  openModal(nationalId: string) {
    this.isModalOpened.next(true);

    this.nationalId.next(nationalId);
  }

  closeModal() {
    this.isModalOpened.next(false);
  }

  resetData() {
    this.closeModal();
    this.nationalId.next('');
  }
}
