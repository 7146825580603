<div
  *ngIf="isModalOpened$ | async"
  class="modal fade nationalIdConfirmationModal show"
  id="nationalIdConfirmationModal"
  aria-labelledby="nationalIdConfirmationModalLabel"
  tabindex="-1"
  style="display: block"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <img
          src="/assets/images/cancel-order-modal/incomplete-order-warning.svg"
          class="img-fluid error-image"
          alt="Madfu"
        />

        <div class="text-container">
          <p class="title mb-1">
            {{ "phone-ownership-retry-limit-exceeded" | translate }}
          </p>

          <p class="subtitle">
            {{ "you-exceeded-phone-retry-limit" | translate }}
          </p>
        </div>

        <button
          type="button"
          class="btn btn-primary confirm-btn"
          (click)="contactCallCenter()"
        >
          {{ "contact-call-center" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isModalOpened$ | async" class="modal-backdrop fade show"></div>
