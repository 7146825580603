export interface SignUpData {
  mobile: string;
  idGuid: string;
  onboardingStep: OnboardingStep;
  nationalId: string;
  random: string;
  transId: string;
  isVerificationPendingAfterOTP: boolean;
}

export enum OnboardingStep {
  otp = 0,
  introduction = 1, // user details
  addresspending = 2, // nafath id verification
  addressverified = 3, // nafath app verification
  verificationPending = 4, // need to change mobile number
}

export interface SignUpSendOTPReq {
  mobile: string;
  mobileCountryCode: string;
}

export interface SignUpSendOTPRes {
  data: {
    idGuid: string;
  };
  succeeded: boolean;
  message: string | null;
  code: number;
}

export interface SignUpOTPVerificationReq {
  plainOtp: string;
  otpId: string;
}

export interface SignUpOTPVerificationRes {
  data: {
    token: string;
    onboardingStep: keyof typeof OnboardingStep;
  };
  succeeded: boolean;
  message: string | null;
  code: number;
}

export interface SignUpPersonalDataReq {
  firstName: string;
  lastName: string;
  email: string;
  gender: number;
  password: string;
  referralCode: string;
  confirmPassword: string;
}

export interface SignUpPersonalDataRes {
  data: {
    successMessage: string;
    onboardingStep: keyof typeof OnboardingStep;
  };
  succeeded: boolean;
  message: string | null;
  code: number;
}

export interface CreateNafathRequestRes {
  data: {
    transId: string;
    random: string;
  };
  succeeded: boolean;
  message: string | null;
  code: number;
}

export interface NafathCheckRes {
  data: {
    idGuid: string;
    remainingAttemptsCount: number;
    totalAttemptsCount: number;
  };
  succeeded: boolean;
  message: string | null;
  code: number;
}
