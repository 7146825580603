<div class="message-container">
  <img
    src="/assets/images/payment-options/unequal-installments.png"
    class=""
    alt="Unequal Installments"
  />

  <div class="text-container">
    <p class="title">
      {{ "down-payment" | translate : { amount: downPayment } }}
    </p>
    <p class="subtitle">
      {{ "first-payment-higher-than-expected" | translate }}
    </p>
    <p class="subtitle">{{ "credit-determined-by-sama" | translate }}</p>
  </div>
</div>
