import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-unequal-installments-payment-message',
  standalone: false,
  templateUrl: './unequal-installments-payment-message.component.html',
  styleUrl: './unequal-installments-payment-message.component.scss',
})
export class UnequalInstallmentsPaymentMessageComponent {
  @Input({ required: true }) downPayment!: number;
}
