<div class="login-page">
  <img
    src="../../../assets/images/login/lg-intersect.svg"
    class="img-fluid lg-intersect-1 d-none d-lg-block"
    alt="intersect"
  />
  <img
    src="../../../assets/images/login/lg-intersect.svg"
    class="img-fluid lg-intersect-2 d-none d-lg-block"
    alt="intersect"
  />

  <img
    src="../../../assets/images/login/sm-intersect.svg"
    class="img-fluid sm-intersect-1 d-lg-none"
    alt="intersect"
  />
  <img
    src="../../../assets/images/login/sm-intersect.svg"
    class="img-fluid sm-intersect-2 d-lg-none"
    alt="intersect"
  />

  <div class="container">
    <app-cancel-order-modal
      [isLogin]="true"
      [orderDetailsRes]="orderDetailsRes"
    />

    <div class="row">
      <div class="col-12 col-lg-6 p-0 order-2 order-lg-1">
        <div class="row">
          <div class="col-12 col-lg-10 offset-xxl-1">
            <p class="flexible-payment">
              {{ "Enjoy flexible payment solutions with MADFU" | translate }}
              <span>{{ "MADFU" | translate }}</span>
              <img
                src="../../../assets/images/login/flexible-payment.svg"
                class="img-fluid ms-2"
                alt="flexible payment"
              />
            </p>

            <h1>{{ "Sign in to Check out" | translate }}</h1>

            <p class="col-12 welcome-title">
              {{ "add-phone-and-password-to-complete-process" | translate }}
            </p>

            <app-login-form [orderDetailsRes]="orderDetailsRes" />

            <div
              class="sharia-compliant d-flex align-items-center justify-content-center"
            >
              <p class="title d-flex align-items-center justify-content-center">
                <img
                  src="../../../assets/images/login/sharia-compliant.svg"
                  class="img-fluid me-1"
                  alt="Sharia Compliant"
                />
                {{ "Sharia-compliant" | translate }}
              </p>

              <p class="subtitle">
                {{ "Sharia-compliant-subtext" | translate }}
              </p>
            </div>

            <div class="col-auto">
              <button
                type="button"
                class="btn btn-text col-12 change-language-btn"
                (click)="changeLanguage()"
              >
                {{ "English" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="col-12 col-lg-5 order-1 order-lg-2 offset-lg-1 p-0 d-flex justify-content-end"
      >
        <img [src]="logoPath" class="img-fluid madfu-img" alt="Madfu" />
      </div>
    </div>
  </div>
</div>
