import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PhoneVerificationFailureService {
  private readonly isFailureModalOpened: Subject<boolean> =
    new Subject<boolean>();
  isFailureModalOpened$: Observable<boolean> =
    this.isFailureModalOpened.asObservable();

  private readonly isChangeMobileModalOpened: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  isChangeMobileModalOpened$: Observable<boolean> =
    this.isChangeMobileModalOpened.asObservable();

  private readonly changingMobileNumber: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  changingMobileNumber$: Observable<boolean> =
    this.changingMobileNumber.asObservable();

  private readonly isReachedTriesLimitsModalOpened: Subject<boolean> =
    new Subject<boolean>();
  isReachedTriesLimitsModalOpened$: Observable<boolean> =
    this.isReachedTriesLimitsModalOpened.asObservable();

  constructor() {}

  openFailureModal() {
    this.isFailureModalOpened.next(true);
  }

  closeFailureModal() {
    this.isFailureModalOpened.next(false);
  }

  openChangeMobileModal() {
    this.isChangeMobileModalOpened.next(true);
  }

  closeChangeMobileModal() {
    this.isChangeMobileModalOpened.next(false);
  }

  openReachedTriesLimitsModal() {
    this.isReachedTriesLimitsModalOpened.next(true);
  }

  startChangeMobileNumberMode() {
    this.changingMobileNumber.next(true);
  }

  endChangeMobileNumberMode() {
    this.changingMobileNumber.next(false);
  }

  closeReachedTriesLimitsModal() {
    this.isReachedTriesLimitsModalOpened.next(false);
  }

  resetData() {
    this.closeFailureModal();
    this.closeChangeMobileModal();
    this.closeReachedTriesLimitsModal();
    this.endChangeMobileNumberMode();
  }
}
