<form [formGroup]="nationalIdForm" (ngSubmit)="openNationalIdModal()">
  <div>
    <label class="input-label" for="nationalId">
      {{ "national-id" | translate }}
    </label>
    <div class="input-group phone-input-group">
      <div class="input-group-prepend">
        <img
          src="/assets/images/sign-up/nationalId.svg"
          class="img-fluid"
          alt="nationalId"
        />
      </div>

      <input
        id="nationalId"
        [placeholder]="'national-id' | translate"
        formControlName="nationalId"
        [ngClass]="{
          'red-border': nationalIdControl.errors && nationalIdControl.touched
        }"
        minlength="10"
        maxlength="10"
      />

      <div
        *ngIf="nationalIdControl.errors && nationalIdControl.touched"
        class="col-12 mt-1 error-message"
      >
        {{ "national-id-error-message" | translate }}
      </div>
    </div>
  </div>

  <button
    type="submit"
    [disabled]="nationalIdForm.invalid"
    class="btn btn-primary continue-button"
  >
    {{ "continue" | translate }}

    <img
      src="/assets/images/login/side-arrow.svg"
      class="img-fluid ms-2"
      alt="arrow"
    />
  </button>
</form>
